import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Button, Form, Input, DatePicker, Modal, message, Table, Popconfirm, Upload, Space, Pagination, Tooltip } from 'antd';
import { PageContainer, ProTable } from '@ant-design/pro-components';
import { UploadOutlined } from '@ant-design/icons';
import './index.scss';
import { request } from '@/utils';
import { getToken } from '../../utils/index';

const { RangePicker } = DatePicker;

const TourList = () => {
  const [dataList, setDataList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState({});
  const formRef = useRef();

  // 假设tableParams是一个包含pagination属性的状态对象
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1, // 当前页数
      pageSize: 20, // 每页记录数
      total: 0,
    },
  });

  const handlePaginationChange = (newPageNum, newPageSize) => {
    setTableParams(prevTableParams => ({
      ...prevTableParams,
      pagination: {
        current: newPageNum,
        pageSize: newPageSize,
      },
    }));
  };


  const columnsDetails = [
    {
      title: '名前',
      width: 150,
      dataIndex: 'cname',
      className: 'custom-column',
    },
    {
      title: '英語名前',
      width: 150,
      dataIndex: 'ename',
      className: 'custom-column',
    },
    {
      title: '性別',
      width: 50,
      dataIndex: 'gender',
      className: 'custom-column',
    },
    {
      title: '生年月日',
      width: 150,
      dataIndex: 'birthday',
      className: 'custom-column',
    },
    {
      title: 'パスポート',
      width: 150,
      dataIndex: 'passport',
      className: 'custom-column',
    },
  ];

  const tableParamsString = useMemo(() => JSON.stringify(tableParams), [tableParams]);

  useEffect(() => {
    handleSearch();
  }, [tableParamsString]);

  const handleSearch = async () => {
    try {
      const { tourNo, guideName, stayDate } = tableParams;
      const entryTimeStart = stayDate ? stayDate[0].format('YYYY-MM-DD') : null;
      const entryTimeEnd = stayDate ? stayDate[1].format('YYYY-MM-DD') : null;

      const requestParms = {
        ...tableParams.pagination,
        tourNo,
        guideName,
        entryTimeStart,
        entryTimeEnd,
      };

      // 发送请求
      const response = await request.post('/tourList', requestParms);

      const processedData = response.data.list.map(item => {
        // 如果 entryTime 存在且为字符串类型，则解析为对象
        const entryTime = (item.entryTime && typeof item.entryTime === 'string') ? JSON.parse(item.entryTime) : item.entryTime;
        const hotelTime = (item.hotelTime && typeof item.hotelTime === 'string') ? JSON.parse(item.hotelTime) : item.hotelTime;

        if (entryTime && Array.isArray(entryTime)) {
          const formattedEntryTime = entryTime.map(timeObj => {
            const { stayDate, stayPlace } = timeObj;

            const formattedDate = stayDate[0] + ' => ' + stayDate[stayDate.length - 1];

            return formattedDate; 

          });

          item.entryTime = formattedEntryTime.join('；');
        }

        if (hotelTime && Array.isArray(hotelTime)) {
          const formattedEntryTime = hotelTime.map(timeObj => {
            const { stayDate, stayPlace } = timeObj;
            const formattedDate = stayDate[0] + ':'; // 获取 stayDate 数组的第一个时间，并加上分号
            return formattedDate + stayPlace;
          });

          item.hotelTime = formattedEntryTime.join('；');
        }
        return {
          ...item,
          entryTime: item.entryTime,
          hotelTime: item.hotelTime,
          persons: (item.persons ? JSON.parse(item.persons) : null),
        };
      });

      // 更新数据
      setDataList(processedData);

      // 更新分页参数
      setTableParams(prevParams => ({
        ...prevParams,
        pagination: {
          ...prevParams.pagination,
          total: response.data.total,
        },
      }));
    } catch (error) {
      console.error('Error searching data:', error);
    }
  };

  // 处理点击“详情”按钮时的逻辑
  const handleShowModal = (record) => {
    setModalData(record.persons); // 将解析后的数据传入模态框
    setModalVisible(true); // 显示模态框
  };

  const handleFilterChange = (newFilters) => {
    setTableParams(prevTableParams => ({
      ...prevTableParams,
      pagination: {
        ...prevTableParams.pagination,
        current: 1, // 其他筛选参数变化时重置 current 为 1
      },
      ...newFilters,
    }));
  };

  // 关闭模态框
  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handleAction = () => {
    // 执行删除逻辑，然后刷新数据
    handleSearch();
  };

  const handleDelete = async (record) => {
    const ids = [record]
    const delRes = await request.post('/delToursByIds', ids)
    setTableParams({
      ...tableParams,
      a: new Date().toLocaleString(),
    });

    if (delRes.code === 200) {
      message.success('削除完了');
    } else if (delRes.code !== 200) {
      message.success('削除失敗');
    }    // 执行删除逻辑，然后刷新数据
    handleAction();
  }

  const token = getToken()
  // const props = {
  //   name: 'file',
  //   action: 'https://r.oneslifejapan.co.jp/api/rebate/importExcel',
  //   headers: {
  //     authorization: `Bearer ${token}`,
  //   },
  //   onChange(info) {
  //     if (info.file.response && info.file.response.code === 200 && info.file.status === 'done') {
  //       message.success(`${info.file.name} アップロード完了`);
  //       setTableParams({
  //         ...tableParams,
  //         a: new Date().toLocaleString(),
  //       });
  //     } else if (info.file.response && info.file.response.code !== 200 && info.file.status === 'done') {
  //       message.error(`${info.file.response.msg}`);
  //     }
  //     handleAction();
  //   },
  // };

  const props = {
    name: 'file',
    action: '', // 禁用默认上传行为
    headers: {
      authorization: `Bearer ${token}`,
    },
    beforeUpload: async (file) => {
      const formData = new FormData();
      formData.append('file', file);
  
      try {
        // 调用 checkTourIsExists 接口
        const checkResponse = await fetch('https://r.oneslifejapan.co.jp/api/rebate/checkTourIsExists', {
          method: 'POST',
          headers: {
            authorization: `Bearer ${token}`,
          },
          body: formData,
        });
  
        const checkResult = await checkResponse.json();
  
        if (checkResult.code === 200) {
          // 如果 code 为 200，手动调用 importExcel 接口
          const importResponse = await fetch('https://r.oneslifejapan.co.jp/api/rebate/importExcel', {
            method: 'POST',
            headers: {
              authorization: `Bearer ${token}`,
            },
            body: formData,
          });
  
          const importResult = await importResponse.json();
  
          if (importResult.code === 200) {
            message.success(`${file.name} アップロード完了`);
            setTableParams({
              ...tableParams,
              a: new Date().toLocaleString(),
            });
          } else {
            message.error(`エラー: ${importResult.msg}`);
          }
        } else if (checkResult.code === 201 || 202) {
          // 如果 code 为 201 或 202，弹出确认框
          Modal.confirm({
            title: '確認してください',
            content: <div dangerouslySetInnerHTML={{ __html: checkResult.msg}} />,
            okText: '確認',
            cancelText: 'キャンセル',
            onOk: async () => {
              // 点击 "是" 时，再次调用 importExcel
              const importResponse = await fetch('https://r.oneslifejapan.co.jp/api/rebate/importExcel', {
                method: 'POST',
                headers: {
                  authorization: `Bearer ${token}`,
                },
                body: formData,
              });
  
              const importResult = await importResponse.json();
  
              if (importResult.code === 200) {
                message.success(`${file.name} アップロード完了`);
                setTableParams({
                  ...tableParams,
                  a: new Date().toLocaleString(),
                });
              } else {
                message.error(`エラーメッセージ: ${importResult.msg}`);
              }
            },
            onCancel() {
              message.info('操作がキャンセルされました');
            },
          });
        } else {
          message.error(`エラーメッセージ: ${checkResult.msg}`);
        }
      } catch (error) {
        message.error('エラー');
      }
  
      return false; // 阻止默认上传行为
    },
    onChange(info) {
      if (info.file.status === 'done') {
        handleAction(); // 调用完成后的自定义逻辑
      }
    },
  };
  
  

  const columns = [
    {
      title: 'ツアー名',
      dataIndex: 'tourNo',
      width: 390,                     
      className: 'custom-column',
    },
    {
      title: 'ガイド',
      dataIndex: 'guideName',
      width: 240,
      className: 'custom-column',
    },
    {
      title: '人数',
      dataIndex: 'peopleNum',
      width: 60,
      className: 'custom-column',
      render: (_, record) => (
        record.persons.length
      )
    },
    {
      title: 'ツアー時間',
      dataIndex: 'entryTime',
      width: 430,
      className: 'custom-column',
      ellipsis: {
        showTitle: false,
      },
      render: (stayDate) => {
        // 获取props属性中的children内容
        const stayDateChildren = stayDate.props.children;
        const formattedStayDate = stayDateChildren.split('；').join('\n');
        return (
          <Tooltip
            placement="top"
            title={<pre>{formattedStayDate}</pre>}// 在 Tooltip 中显示完整内容
            overlayClassName="custom-tooltip"
            mouseEnterDelay={1}
            color="blue"
          >
            {stayDate}
          </Tooltip>

        );
      },
    },
    // {
    //   title: '宿泊日',
    //   dataIndex: 'hotelTime',
    //   width: 420,
    //   className: 'custom-column',
    //   ellipsis: {
    //     showTitle: false,
    //   },
    //   render: (stayDate) => {
    //     // 获取props属性中的children内容
    //     const stayDateChildren = stayDate.props.children;
    //     const formattedStayDate = stayDateChildren.split('；').join('\n');
    //     return (
    //       <Tooltip
    //         placement="top"
    //         title={<pre>{formattedStayDate}</pre>}// 在 Tooltip 中显示完整内容
    //         overlayClassName="custom-tooltip"
    //         mouseEnterDelay={1}
    //         color="blue"
    //       >
    //         {stayDate}
    //       </Tooltip>

    //     );
    //   },
    // },
    {
      title: '操作ログ',
      dataIndex: 'updateUser',
      width: 120,
      className: 'custom-column',
    },
    {
      title: '操作',
      width: 80,
      align: 'center',
      render: (_, record) => (
        <Space size="small">
          <Button onClick={() => handleShowModal(record)} style={{ background: 'none', border: 'none', color: 'blue' }}>詳細</Button>
          <Popconfirm
            title="削除しますか?"
            onConfirm={() => handleDelete(record.id)}
            okText="削除"
            cancelText="キャンセル"
            style={{ textAlign: 'center' }}
          >
            <Button style={{ background: 'none', border: 'none', color: 'blue' }}>削除</Button>
          </Popconfirm>
        </Space>
      )
    }
  ];

  return (

    <PageContainer style={{ marginTop: '-50px' }}>
      <div className="custom-div">
        <Form
          ref={formRef}
          onFinish={handleFilterChange}
          layout="inline"
        >
          <Form.Item name="tourNo">
            <Input placeholder="ツアー名" />
          </Form.Item>
          <Form.Item name="guideName">
            <Input placeholder="ガイド名" />
          </Form.Item>
          <Form.Item name="stayDate">
            <RangePicker placeholder={['開始日付', '終了日付']} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="custom-button">検索</Button>
          </Form.Item>
          <Form.Item>
            <Upload {...props} >
              <Button icon={<UploadOutlined />}>アップロード</Button>
            </Upload>
          </Form.Item>
        </Form>
        <div style={{ overflowY: 'auto' }}>
          <ProTable className='custom-column'
            headerTitle=""
            columns={columns}
            rowKey="key"
            dataSource={dataList}
            pagination={false}
            options={false}
            search={false}
            style={{ marginTop: '7px', width: '100%' }}
            rowClassName="compact-row"
            scroll={{ y: 'calc(90vh - 150px)', x: 'max-content' }}
          />
        </div>
        {/* 分页器 */}
        <Pagination
          current={tableParams.pagination.current} // 当前页数
          pageSize={tableParams.pagination.pageSize} // 每页记录数
          total={tableParams.pagination.total} // 数据总数，这里是一个示例值，您可以根据实际情况替换
          onChange={handlePaginationChange} // 分页器改变事件
          showSizeChanger // 是否显示每页记录数切换器
          pageSizeOptions={['20', '30', '50', '100']} // 每页记录数选项
          showTotal={(total) => `計 ${total}`} // 显示总条目数
          style={{ position: 'absolute', bottom: '-2px', right: '10px' }} // 调整分页器位置为右下角
        />
      </div>
      <Modal
        title="詳細"
        open={modalVisible}
        onCancel={handleCloseModal}
        footer={null}
        width="80%" // 设置模态框宽度为屏幕宽度的80%
      >
        <Table
          columns={columnsDetails}
          dataSource={modalData}
          pagination={false} // 去掉分页
          scroll={{ y: 'calc(90vh - 150px)', x: 'max-content' }} // 使用 calc() 函数计算表格的高度
        />
      </Modal>
    </PageContainer>

  );
};

export default TourList;
