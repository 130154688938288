import React, { useEffect, useState } from 'react';
import { List, Table, Checkbox, Button, DatePicker, Input, message } from 'antd';
import './index.scss'
import { request } from '@/utils';
import { UserOutlined } from '@ant-design/icons';
const { RangePicker } = DatePicker;
const Home = () => {
  // 存放勾选项
  const [selectedItems, setSelectedItems] = useState([]);
  // 是否勾选全部
  const [selectAll, setSelectAll] = useState(false);
  const [listpagination, setListpagination] = useState(null);
  const [data, setData] = useState([
    {
      "data": [
        {
          "key": "b6c655aa773a45bfab96fb4dde1e6a6d",
          "entryTime": null,
          "tourNo": null,
          "guideName": null,
          "shopName": null,
          "phoneNum": null,
          "pointGoods": null,
          "goodPrice": null,
          "totalGoods": null,
          "salesAmount": null,
          "companyRPrice": null,
          "pointRPrice": 0,
          "companyRAmount": null,
          "pointRAmount": null,
          "totalSales": null,
          "generalGoods": null,
          "companyGeneral": null,
          "guideGeneral": null,
          "guideAmount": null
        },
        {
          "key": "0019ce1a11df4a4e87ee73c06065ccbb",
          "entryTime": null,
          "tourNo": null,
          "guideName": null,
          "shopName": null,
          "phoneNum": null,
          "pointGoods": null,
          "goodPrice": null,
          "totalGoods": null,
          "salesAmount": null,
          "companyRPrice": null,
          "pointRPrice": 0,
          "companyRAmount": null,
          "pointRAmount": null,
          "totalSales": null,
          "generalGoods": null,
          "companyGeneral": null,
          "guideGeneral": null,
          "guideAmount": null
        },
        {
          "key": "13047fc4831548f19a958b1dcb9e5a0c",
          "entryTime": null,
          "tourNo": null,
          "guideName": null,
          "shopName": null,
          "phoneNum": null,
          "pointGoods": null,
          "goodPrice": null,
          "totalGoods": null,
          "salesAmount": null,
          "companyRPrice": null,
          "pointRPrice": 0,
          "companyRAmount": null,
          "pointRAmount": null,
          "totalSales": null,
          "generalGoods": null,
          "companyGeneral": null,
          "guideGeneral": null,
          "guideAmount": null
        },
        {
          "key": "ab3cbe3034c74b4f8eb525c37d0a064d",
          "entryTime": null,
          "tourNo": null,
          "guideName": null,
          "shopName": null,
          "phoneNum": null,
          "pointGoods": null,
          "goodPrice": null,
          "totalGoods": null,
          "salesAmount": null,
          "companyRPrice": null,
          "pointRPrice": 0,
          "companyRAmount": null,
          "pointRAmount": null,
          "totalSales": null,
          "generalGoods": null,
          "companyGeneral": null,
          "guideGeneral": null,
          "guideAmount": null
        }
      ],
      "id": "f5f5b8e97a1b4154ae36c8d50683b62e"
    }
  ]);

  const [loading, setLoading] = useState(false);
  const [entryTimeStart, setEntryTimeStart] = useState(null);
  const [entryTimeEnd, setEntryTimeEnd] = useState(null);
  const [guidInputValue, setGuidInputValue] = useState(null);
  const [tourNoInputValue, setTourNoInputValue] = useState(null);

  useEffect(() => {
    console.log(selectedItems)
  }, [selectedItems])
  useEffect(() => {
    console.log('页码状态改变', listpagination)
  }, [listpagination])

  useEffect(() => {
    async function getlist() {
      const requestParms = {
        entryTimeEnd: null,
        entryTimeStart: null,
        guideName: null,
        tourNo: null,
        type: 1
      }
      const res = await request.post('/compute', requestParms,{ timeout: 15000 })
      console.log(res)
      if (res.data) {
        setData(res.data)
      }

    }
    getlist()
    // }, [tableParams]);
  }, []);
  // 表格筛选信息变化的话，更新筛选信息
  // const handleChange = (pagination) => {
  //   console.log(pagination)

  //   setListpagination({
  //     ...tableParams,
  //     pagination,
  //   });
  // `dataSource` is useless since `pageSize` changed
  // if (pagination.pageSize !== tableParams.pagination?.pageSize) {
  //   setData([]);
  // }

  // };
  const handleCheckboxChange = (itemId) => {
    // 该项已经被选中时，将它从selectedItems中过滤除去
    // 未被选中时，放入selectedItems中
    const newSelectedItems = selectedItems.includes(itemId)
      ? selectedItems.filter((id) => id !== itemId)
      : [...selectedItems, itemId];
    setSelectedItems(newSelectedItems);
    setSelectAll(false);
  };

  const handleSelectAllChange = () => {

    // 得到数据列表的所有表格的id
    const allItemIds = data.map((item) => item.id);
    // 当前selectAll为true时，将selectedItems清空
    // 当前selectAll为false时，将selectedItems设置为所有
    const newSelectedItems = selectAll ? [] : allItemIds;
    setSelectedItems(newSelectedItems);
    // 最后更新selectAll状态
    setSelectAll(!selectAll);
  };

  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      setEntryTimeStart(dates[0]);
      setEntryTimeEnd(dates[1]);
    }
    else {
      setEntryTimeStart(null);
      setEntryTimeEnd(null);
    }
  };
  const handleInputChange = (e) => {
    setGuidInputValue(e.target.value.replace(/(^\s*)|(\s*$)/g, ''));
  };
  const handleInputChangetwo = (e) => {
    setTourNoInputValue(e.target.value.replace(/(^\s*)|(\s*$)/g, ''));
  };
  const requestParms = {
    entryTimeEnd: entryTimeEnd ? entryTimeEnd.format('YYYY-MM-DD') : null,
    entryTimeStart: entryTimeStart ? entryTimeStart.format('YYYY-MM-DD') : null,
    guideName: guidInputValue,
    tourNo: tourNoInputValue,
    type: 1
  }
  const handleButtonClick = async () => {
    // if (entryTimeStart && entryTimeEnd) {
    //   console.log('开始日期:', entryTimeStart.format('YYYY-MM-DD'));
    //   console.log('结束日期:', entryTimeEnd.format('YYYY-MM-DD'));
    // } else {
    //   console.log('请选择开始和结束日期');
    // }
    setLoading(true);
    setSelectedItems([]);
    const res = await request.post('/compute', requestParms ,{ timeout: 15000 })
    setLoading(false);
    if (res.data) {
      setData(res.data)
    } else {
      setData([
        {
          "data": [
            {
              "key": "b6c655aa773a45bfab96fb4dde1e6a6d",
              "entryTime": null,
              "tourNo": null,
              "guideName": null,
              "shopName": null,
              "phoneNum": null,
              "pointGoods": null,
              "goodPrice": null,
              "totalGoods": null,
              "salesAmount": null,
              "companyRPrice": null,
              "pointRPrice": 0,
              "companyRAmount": null,
              "pointRAmount": null,
              "totalSales": null,
              "generalGoods": null,
              "companyGeneral": null,
              "guideGeneral": null,
              "guideAmount": null
            },
            {
              "key": "0019ce1a11df4a4e87ee73c06065ccbb",
              "entryTime": null,
              "tourNo": null,
              "guideName": null,
              "shopName": null,
              "phoneNum": null,
              "pointGoods": null,
              "goodPrice": null,
              "totalGoods": null,
              "salesAmount": null,
              "companyRPrice": null,
              "pointRPrice": 0,
              "companyRAmount": null,
              "pointRAmount": null,
              "totalSales": null,
              "generalGoods": null,
              "companyGeneral": null,
              "guideGeneral": null,
              "guideAmount": null
            },
            {
              "key": "13047fc4831548f19a958b1dcb9e5a0c",
              "entryTime": null,
              "tourNo": null,
              "guideName": null,
              "shopName": null,
              "phoneNum": null,
              "pointGoods": null,
              "goodPrice": null,
              "totalGoods": null,
              "salesAmount": null,
              "companyRPrice": null,
              "pointRPrice": 0,
              "companyRAmount": null,
              "pointRAmount": null,
              "totalSales": null,
              "generalGoods": null,
              "companyGeneral": null,
              "guideGeneral": null,
              "guideAmount": null
            },
            {
              "key": "ab3cbe3034c74b4f8eb525c37d0a064d",
              "entryTime": null,
              "tourNo": null,
              "guideName": null,
              "shopName": null,
              "phoneNum": null,
              "pointGoods": null,
              "goodPrice": null,
              "totalGoods": null,
              "salesAmount": null,
              "companyRPrice": null,
              "pointRPrice": 0,
              "companyRAmount": null,
              "pointRAmount": null,
              "totalSales": null,
              "generalGoods": null,
              "companyGeneral": null,
              "guideGeneral": null,
              "guideAmount": null
            }
          ],
          "id": "f5f5b8e97a1b4154ae36c8d50683b62e"
        }
      ]
      )
    }
    if (!requestParms.entryTimeStart) {
      message.warning("日付は必要です");
    } else if (res.data === null) {
      message.warning("データはありません");
    }
    console.log('输入框内容:', guidInputValue);
    console.log('输入框内two:', tourNoInputValue);
  };
  const renderItem = item => (
    <List.Item className="list-item">
      <Checkbox
        onChange={() => handleCheckboxChange(item.id)}
        checked={selectedItems.includes(item.id)}
        style={
          {
            marginRight: '5px',
          }
        }
      />
      <Table bordered columns={columns} dataSource={item.data} pagination={false} />
    </List.Item>
  );
  const StatisticsButtonClick = async () => {
    setLoading(true)
    await request.post('/exportStatistics', requestParms, {
      // 设置responseType对象格式为blob
      responseType: "blob",
      timeout: 15000
    }).then(res => {
      // 创建下载的链接
      const url = window.URL.createObjectURL(new Blob([res],
        // 设置该文件的mime类型，这里对应的mime类型对应为.xlsx格式                          
        { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
      const link = document.createElement('a');
      link.href = url;
      // 从header中获取服务端命名的文件名
      const fileName = '1213';
      link.setAttribute('download', 'filename.xlsx')
      document.body.appendChild(link);
      link.click();
      message.success('発行成功')
    })
    setLoading(false)
  }

  // 调用导出接口，可以看标准版的调用接口api的方法
  const excelExport = async (typenumb) => {
    try {
      const allIds = data.map(item => item.id);


      if (selectedItems.length && data[0].id !== 'f5f5b8e97a1b4154ae36c8d50683b62e') {
        const inforequest = { ids: selectedItems, type: typenumb };
        request.post('/export', inforequest, {
          // 设置responseType对象格式为blob
          responseType: "blob",
        }).then(res => {
          // 创建下载的链接
          const url = window.URL.createObjectURL(new Blob([res],
            // 设置该文件的mime类型，这里对应的mime类型对应为.xlsx格式                          
            { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
          const link = document.createElement('a');
          link.href = url;
          // 从header中获取服务端命名的文件名
          const fileName = '1213';
          link.setAttribute('download', 'filename.xlsx')
          document.body.appendChild(link);
          link.click();
          message.success('発行成功')
        })
      } else if (data[0].id !== 'f5f5b8e97a1b4154ae36c8d50683b62e') {
        const inforequest2 = { ids: allIds, type: typenumb };
        request.post('/export', inforequest2, {
          // 设置responseType对象格式为blob
          responseType: "blob",
        }).then(res => {
          // 创建下载的链接
          const url = window.URL.createObjectURL(new Blob([res],
            // 设置该文件的mime类型，这里对应的mime类型对应为.xlsx格式                          
            { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
          const link = document.createElement('a');
          link.href = url;
          // 从header中获取服务端命名的文件名
          const fileName = '1213';
          link.setAttribute('download', 'filename.xlsx')
          document.body.appendChild(link);
          link.click();
          message.success('発行成功')
        })
      }

    } catch (error) {
      console.error('Error downloading Excel file:', error);
    }
  };
  const columns = [
    {
      title: '入店日',
      dataIndex: 'entryTime',
      key: 'entryTime',
      width: 90,
    },
    {
      title: 'ツアー名',
      dataIndex: 'tourNo',
      key: 'tourNo',
      width: 180,
    },
    {
      title: 'お店',
      dataIndex: 'shopName',
      key: 'shopName',
      width: 110,
    },
    {
      title: '添乗員名',
      dataIndex: 'guideName',
      key: 'guideName',
      width: 80,
    },
    ,
    {
      title: '携帯電話',
      dataIndex: 'phoneNum',
      key: 'phoneNum',
      width: 80,
    },
    {
      title: '人数',
      dataIndex: 'travelers',
      key: 'travelers',
      width: 70,
    },
    ,
    {
      title: '総売上',
      dataIndex: 'totalSales',
      key: 'totalSales',
      width: 80,
    },
    {
      title: 'ポイント商品',
      dataIndex: 'pointGoods',
      key: 'pointGoods',
      width: 100,
    },
    ,
    {
      title: '単価',
      dataIndex: 'goodPrice',
      key: 'goodPrice',
      width: 80,
    },
    {
      title: '数量',
      dataIndex: 'totalGoods',
      key: 'totalGoods',
      width: 80,
    },
    ,
    {
      title: '売上金額',
      dataIndex: 'salesAmount',
      key: 'salesAmount',
      width: 80,
    },
    {
      title: '会社R単価',
      dataIndex: 'companyRPrice',
      key: 'companyRPrice',
      width: 80,
    },
    ,
    {
      title: 'ポイントR',
      dataIndex: 'pointRPrice',
      key: 'pointRPrice',
      width: 80,
    },
    {
      title: '会社R計',
      dataIndex: 'companyRAmount',
      key: 'companyRAmount',
      width: 80,
    }, ,
    {
      title: 'ポイントR計',
      dataIndex: 'pointRAmount',
      key: 'pointRAmount',
      width: 80,
    },
    ,
    {
      title: '一般商品',
      dataIndex: 'generalGoods',
      key: 'generalGoods',
      width: 80,
    },
    {
      title: '会社一般R(8%)',
      dataIndex: 'companyGeneral',
      key: 'companyGeneral',
      width: 60,
    },
    ,
    {
      title: 'ガイド一般R(5%)',
      dataIndex: 'guideGeneral',
      key: 'guideGeneral',
      width: 80,
    },
    {
      title: 'ガイドR合計金額',
      dataIndex: 'guideAmount',
      key: 'guideAmount',
      width: 80,
    },
  ];
  const disabledDate = (current, { from }) => {
    if (from) {
      return Math.abs(current.diff(from, 'days')) >= 7;
    }
    return false;
  };

  return (
    <div>

      <div style={{ marginBottom: 16 }}>
        <Checkbox onChange={handleSelectAllChange} checked={selectAll}>すべて選択</Checkbox>
        {/* <span>日付範囲</span> */}
        <RangePicker onChange={handleDateChange} disabledDate={disabledDate} style={{
          width: '15%',
          marginRight: '20px',
        }} />
        <span>ガイド</span>
        <Input placeholder="藤井" onChange={handleInputChange} prefix={<UserOutlined />} style={{
          width: '10%',
          marginRight: '20px',
        }} />
        <span>ツアー番号</span>
        <Input placeholder="KMM-D-240222SZSH-DD" onChange={handleInputChangetwo} style={{
          width: '15%',
          marginRight: '20px',
        }} />
        <Button onClick={handleButtonClick} style={{
          marginRight: '100px',
        }} type="primary">検索</Button>
        <Button onClick={() => excelExport(1)} style={{

          marginRight: '20px',
        }}>会社ファイル</Button>
        <Button onClick={() => excelExport(2)} style={{

          marginRight: '20px',
        }}>ガイドファイル</Button>
        <Button onClick={StatisticsButtonClick} style={{
          marginRight: '20px',
        }}>統計</Button>

      </div>
      <List
        pagination={{
          position: 'bottom',
          align: 'center',
          pageSize: 3,
          showTotal: () => `${data.length}件`,
          onChange: (page) => {
            setListpagination(
              page
            );
          },
        }
        }
        loading={loading}
        itemLayout="horizontal"
        dataSource={data}
        renderItem={renderItem}
      // onChange={handleChange}
      />
    </div>
  );
};

export default Home;
